.gridLasterUrtarrila {
    .lista{
        &__item{
            
            .a {
                background-image : url(../img/Antzoki/enero/1.jpg);
            }
            .b{
                background-image : url(../img/Antzoki/enero/2.jpg);
            }
            .c{
                background-image : url(../img/Antzoki/enero/3.jpg);
            }
            .d {
                background-image : url(../img/Antzoki/enero/4.jpg);
            }
            .e {
                background-image : url(../img/Antzoki/enero/5.jpg);
            }
            .f {
                background-image : url(../img/Antzoki/enero/6.jpg);        
            }
            .g {
                background-image : url(../img/Antzoki/enero/7.jpg);
            }
            .h {
                background-image : url(../img/Antzoki/enero/8.jpg);
                background-position : left;
            }
        }
    }
}

.gridLaster_Martxoa{
    .lista{
        .x{
            background-image : url(../img/Antzoki/marzo/0.jpg);
            background-position : left center;
        }
        .a {
            background-image : url(../img/Antzoki/marzo/1.jpg);
            background-position: left center;
        }
        .b{
            background-image : url(../img/Antzoki/marzo/2.jpg);
            background-position :  left  top;
        }
        .c{
            background-image : url(../img/Antzoki/marzo/3.jpg);
            background-position : center;
        }
        
        .d {
            background-image : url(../img/Antzoki/marzo/4.jpg);
            background-position : center;
        }
        .e {
            background-image : url(../img/Antzoki/marzo/5.jpg);
            background-position: center ;
        }
        .f {
            background-image : url(../img/Antzoki/marzo/6.jpg);
            background-position:  -9rem center;
        }
        @media (min-width: 762px) {  
            .f {
                background-image : url(../img/Antzoki/marzo/6.jpg);
                background-position: center;
            }
        }
        .g {
            background-image : url(../img/Antzoki/marzo/7.jpg);
            background-position: center top;
        }
        .h {
            background-image : url(../img/Antzoki/marzo/8.jpg);
            background-position : center;
        }
        .i{
            background-image : url(../img/Antzoki/marzo/9.jpg);
            background-position: 40% center;
        }
        .j{
            background-image : url(../img/Antzoki/marzo/10.jpg);
            background-position: 40% center;
        }
    }
}

.grid_santana {
    width            : 100%;
    background-image : url(../img/menu/santaAna.jpg);
    line-height      : 6;
    background-size  : cover;
    background-position: center 45%;
    h2{
        text-align    : center;
        border-top    : var(--border_color);
        border-bottom : var(--border_color);
        font-family   : var(--body_tipo);
        a{
            text-transform   : uppercase;
            text-align       : center;
            font-weight      : 400;
            background-color : rgba(#353D40, .8);
            border-radius    : .2em;
            padding          : 1em 1rem;
            transition       : all 1s;
        }
    }
}

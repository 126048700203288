
html{
    @include box-sizing(border-box);
    font-size: 62.5%;
}
*,
*:after,
*:before{
    @include box-sizing(inherit);
    margin      : 0;
    padding     : 0;
    line-height : 1.6;
}

body{
    font-family    : $fuente_principal;
    max-width      : 100vw;
    min-height     : 100vh;   
    font-size      : 1.6rem;
    font-optical-sizing: auto;
    font-weight    : 400;
    font-style     : normal;
    min-block-size : 100dvb;
    margin-block   : 0;
}

//todo: Imágenes responsive
img {
    @include imagen();
}

a {
    text-decoration: none;
    color : $blanco;
    cursor: pointer;
}

h1,h2,h3,h4{
    text-transform      : uppercase;
    font-family         : $fuente_principal;
    font-optical-sizing : auto;
    font-weight         : 600;
    // margin              : 0 0 2rem 0;
    // letter-spacing      :.3rem;
}

#logotipo {
    position         : fixed;
    display          :flex;
    justify-content  : space-between;
    top              : 0;
    z-index          : 1000;
    background-color : $azul_berango;
    width            : 100vw;
   
    .lenguaje{
        display          : flex;
        align-items      : center;
        justify-content  : center;
        padding          : 0 2.5rem;    
        border-radius    : 25% 25%;  
        background-color : rgba($blanco, .1);
        margin-right     : 1rem;
        a:focus{
            color : $negro;
        }
    }
}

button {
    @include button();
    cursor: pointer;
}

@media (max-width : 736px) and (orientation : landscape) {
    body {
        font-size : var(--fz_body_tablet);
        h2{
            font-size: var(--h2_tablet);
        }
        padding-top : 0;
        #logotipo {
            display : none;
        }
    }
}
/* Fade effect */

.js body {
    opacity    : 0;
    transition : opacity 0.3s;
}
.js body.render {
    opacity : 1;
}

/* Page Loader */
.js .loading::before {
    content    : '';
    position   : fixed;
    z-index    : 100000;
    top        : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;
    background : var(--color-bg);
}
.js .loading::after {
    content        : '';
    position       : fixed;
    z-index        : 100000;
    top            : 50%;
    left           : 50%;
    width          : 60px;
    height         : 60px;
    margin         : -30px 0 0 -30px;
    pointer-events : none;
    border-radius  : 50%;
    opacity        : 0.4;
    background     : $negro;
    animation      : loaderAnim 0.7s linear infinite alternate forwards;
}
@keyframes loaderAnim {
    to {
        opacity   : 1;
        transform : scale3d(0.5, 0.5, 1);
    }
}
a {
    text-decoration : none;
    color           : $blanco;
    outline         : none;
}
a:hover,a:focus,a:visited {
    color   : $blanco;
    outline : none;
}
button:focus {
    outline : none;
}
.hidden {
    position       : absolute;
    overflow       : hidden;
    width          : 0;
    height         : 0;
    pointer-events : none;
}
.content {
    position        : relative;
    display         : flex;
    justify-content : center;
    align-items     : center;
    flex-direction  : column;
    min-height      : 0;
    top             : 0;
    overflow        : hidden;
}
.content--fixed {
    position : relative;
    z-index  : 1000;
    display  : block;
}
main {
    position : relative;
    width    : 100%;
}

@media screen and (min-width : 55em) {
   
    #logotipo {
        display : none;
    }
    .content {
        border-bottom : 0;
        margin-left   : auto;
        margin-right  : auto;
        width         : 100%;
        min-height    : 100vh;
    }
    #content-2, #content-3, #content-4, #content-5, #content-6, 
    #content-7, #content-8, #content-9{
        position: absolute;
        top: 0;
    }
    .js .content--switch {
        display : none;
    }
    .js .content--switch-current {
        display : flex;
    }
    /* Posición botón menu */
    .content--fixed {
        position              : fixed;
        z-index               : 100;
        top                   : 0;
        left                  : 0;
        display               : grid;
        align-content         : space-between;
        width                 : 100vw;
        max-width             : none;
        min-height            : 0;
        height                : 100vh;
        padding               : 1.5em;
        pointer-events        : none;
        grid-template-columns : 70% 30%;
        grid-template-rows    : auto auto 4em;
        grid-template-areas   : 'header ...' '... ...' 'menu demos';
    }
    .content--fixed a {
        pointer-events : auto;
    }
    .content__title,
    .content__subtitle {
        display : none;
    }
}

.negrita{
    font-weight: bold;
}  

.alerta{
    display: none;
}

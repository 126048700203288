
.cursos{
    width: 100%;

    .cursos-item{
        width: 90%;
        margin: 0 auto;
        display : grid;
        // grid-template-columns: 25% 28% 12% 10% 10% 15%; // Cursos adultos
        grid-template-columns: 15% 15% 15% 15% 15% 17% 8%; // Cursos infantil
        font-size: 1.6vh;
        align-items: center;

        h4{
            background-color: $azul_berango;
            color: $blanco;
            padding: 2vh 0;
            text-align: center;
        }

        p{
            padding: 1vh 1vw;
            text-align: left;
            background-color: rgba($gris, .3);
            border-bottom: 1px solid $gris;
            text-align: center;
        }

        p:nth-child(odd) {
            background: rgba($azul_berango, .15);         
        }          
    } 
}

.button{
    width: 100%;
    text-align: center;
    margin: 2vh 0;
 }


.protocolo{
    padding-bottom : 3vh;
    font-size: 1.6vh;
   
    h1{
        margin: 0;
        background-color : $azul_berango;
        color            : $blanco;
    }
    .normativa{
        height                : 100%;
        margin                : auto;
        margin                : auto;
        
        .normativa-item{

            ul li{
                text-decoration: none;
                padding: 1vh 0;
            }

        } 

        .normativa-item:nth-child(1){
            flex-direction : column;
            margin         : auto; 
            align-content  : center;       
        }

        img{
            max-width : 40%;
            height    : auto;
        }

        .normativa-item:nth-child(2){
            background-color : rgba($azul_berango, .15);
            padding          : 3vh;
        }
    }
}

@media (min-width : 55em){
    .cursos{
        width: 94%;
        margin: auto;
    }

    .protocolo{
        width: 90%;
        margin: auto;

       .normativa{
        display               : grid;
        grid-template-columns : 30% 1fr;
        
            img{
                max-width : 100%;
                height    : auto;
            }
        }      
    }
}

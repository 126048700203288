
.cabecera-liburutegia{
    margin-top: 8rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:space-around;
    padding: 0;

    .liburutegia{
        font-size: 2rem;
    }

    .calendario{
        max-width: 100% ;
        width: 5rem;
    }
   
}
.grid_novedadesLiburutegia{
    background-color: #F0F2F5;
    width: 100vw;
    margin-top: 2rem;
    
    .a,.b,.c,.d,.e,.f,.g,.h,.i,.j,.k,.l,.ll,.m,.n,.o,.p,.q{
        flex-direction  : column;
        justify-content : center;
        align-items     : center;
        padding: 3rem 2rem 4rem; 
        border-bottom : $border_color;
        picture, img{
            width: 65%;
            margin: 3rem auto;
        }
        h2{
            font-size: 2.5rem;
        }
        p{
            text-align: center;
            a{
                color: $negro;
            }
        }        
        
        h3{       
            a {
                @include enlace();
                
            }          
        }       
    } 
    .q{
        border-bottom : 1em solid $azul_berango;
    }  
}
/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .grid_novedadesLiburutegia{
        font-size : var(--fz_body_movil);
    }
}
@media screen and (min-width : 55rem) {
    .cabecera-liburutegia{                   
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        .liburutegia{
            font-size: 5rem;
        }
        
        .calendario{
            margin-left: 2rem;
            max-width: 100% ;
            width: 8rem;
        }
       
    }
    .grid_novedadesLiburutegia{
        
        @include grid();
        
        .a,.b,.c,.d,.e,.f,.g,.h,.i,.j,.k,.l,.ll,.m,.n,.o,.p,.q{
            border-bottom : unset;
            border-top    : $border_color;
            border-right  : $border_color;  
            height        : 100%;   
            display       : flex; 
            padding       : 3rem 2rem 4rem; 

            img{ 
                width: unset;             
                height: 65%;
            }
            h2{
                font-size: 2.5rem;
            }
            h3{
                font-size: 1.8rem;                
            }         
        }
        .ll,.m,.n,.o,.p,.q{
            border-bottom : $border_color;
        }
        .a {
            grid-column : 1 / 6;
            grid-row    : 1 / 11;
        }
        .b {
            grid-column : 6 / 11;
            grid-row    : 1 / 11;
        }
        .c {
            grid-column : 11 / 16;
            grid-row    : 1 / 11;
        }
        .d {
            grid-column : 16 / 22;
            grid-row    : 1 / 11;
        }
        .e {
            grid-column : 22 / 28;
            grid-row    : 1 / 11;
        }
        .f {
            grid-column : 28 / 33;
            grid-row    : 1 / 11;
        }
        .g {
            grid-column : 1 / 6;
            grid-row    : 11 / 22;
        }
        .h {
            grid-column : 6 / 11;
            grid-row    : 11 / 22;
        }
        .i {
            grid-column : 11 / 16;
            grid-row    : 11 / 22;
        }
        .j {
            grid-column : 16 / 22;
            grid-row    : 11 / 22;
        }
        .k {
            grid-column : 22 / 28;
            grid-row    : 11 / 22;
        }
        .l {
            grid-column : 28 / 33;
            grid-row    : 11 / 22;
        }
        .ll {
            grid-column : 1 / 6;
            grid-row    : 22 / 33;
        }
        .m {
            grid-column : 6 / 11;
            grid-row    : 22 / 33;
        }
        .n {
            grid-column : 11 / 16;
            grid-row    : 22 / 33;
        }
        .o {
            grid-column : 16 / 22;
            grid-row    : 22 / 33;
        }
        .p {
            grid-column : 22 / 28;
            grid-row    : 22 / 33;
        }
        .q {
            grid-column : 28 / 33;
            grid-row    : 22 / 33;
        }
       
    }
}

.capitulos{
    position: relative;
    margin-top: 8rem;
    text-align: center;
    font-size: 2rem;
    color: $negro;
    @media (min-width: 768px) {  
        font-size: 3rem;
        margin: 0;
    }
}
.grid_capitulos{
    width: 100vw;
    background-color: rgba($gris, .05);
   
    
    .a,.b,.c,.d,.e,.f,.g,.h,.i,.j,.k,.l,.ll,.m,.n,.o,.p,.q{
        border-bottom   : 1px solid $blanco; 
        padding: 2rem 0;

        .contenido{
            &__imagen{
                img{               
                    max-width: 35rem;
                    margin: 0 auto;
                    height: 100%;           
                }
            }

            &__box{
                a{
                    margin: 2rem 0 0 0;
                    background-color: $azul_berango;
                }

                h3{
                    font-weight : bold;
                    color: $blanco;
                    
                } 

                p{
                    margin: 0;                                
                }            
            }
        }  
        border-bottom : $border_color;
        
        h3{       
            a {
                @include enlace();
            }          
        }
        &:hover{
            opacity: 1;
        } 
      
        .volver{
            font-size: 2rem;
            padding: .5rem 1.5rem;
            background-color: $azul_berango;
            color: #fff;
            font-weight: bold;  
        }
        .tex-center{
            text-align: center;
        }
    } 
    .q{
        padding-bottom: 5rem;
        border-bottom : 1em solid $azul_berango;
        display: none;
    }  
    
}
/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .grid_novedadesLiburutegia{
        font-size : var(--fz_body_movil);
    }
}
@media screen and (min-width : 55em) {

    h1{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        a{
            display: block;
        }
    }
    .grid_capitulos{      
        
        @include grid();
        
        .a,.b,.c,.d,.e,.f,.g,.h,.i,.j,.k,.l,.ll,.m,.n,.o,.p,.q{
            border-bottom : unset;
            border-top    : $border_color;
            border-right  : $border_color;  
            height        : 100%;   
            display       : flex;     
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 0;

            .contenido{
                &__imagen{
                    img{
                        max-width: 20rem;
                        width: 100%;            
                    }
                }

                &__box{
                     h3{
                    font-size: 1.4rem;
                    }    
                }
            }
                
        }
        .ll,.m,.n,.o,.p,.q{
            border-bottom : $border_color;
        }
        .a {
            grid-column : 1 / 6;
            grid-row    : 1 / 11;
        }
        .b {
            grid-column : 6 / 11;
            grid-row    : 1 / 11;
        }
        .c {
            grid-column : 11 / 16;
            grid-row    : 1 / 11;
        }
        .d {
            grid-column : 16 / 22;
            grid-row    : 1 / 11;
        }
        .e {
            grid-column : 22 / 28;
            grid-row    : 1 / 11;
        }
        .f {
            grid-column : 28 / 33;
            grid-row    : 1 / 11;
        }
        .g {
            grid-column : 1 / 6;
            grid-row    : 11 / 22;
        }
        .h {
            grid-column : 6 / 11;
            grid-row    : 11 / 22;
        }
        .i {
            grid-column : 11 / 16;
            grid-row    : 11 / 22;
        }
        .j {
            grid-column : 16 / 22;
            grid-row    : 11 / 22;
        }
        .k {
            grid-column : 22 / 28;
            grid-row    : 11 / 22;
        }
        .l {
            grid-column : 28 / 33;
            grid-row    : 11 / 22;
        }
        .ll {
            grid-column : 1 / 6;
            grid-row    : 22 / 33;
        }
        .m {
            grid-column : 6 / 11;
            grid-row    : 22 / 33;
        }
        .n {
            grid-column : 11 / 16;
            grid-row    : 22 / 33;
        }
        .o {
            grid-column : 16 / 22;
            grid-row    : 22 / 33;
        }
        .p {
            grid-column : 22 / 28;
            grid-row    : 22 / 33;
        }
        .q {
            grid-column : 28 / 33;
            grid-row    : 22 / 33;
        }       
    }
}

.gridLasterApirila {
    .lista{
        .x{
            background-image : url(../img/Antzoki/abril/0.png);
            background-position : center;
        }
        .a {
            background-image : url(../img/Antzoki/abril/1.png);
            background-position: center top;
        }
        .b{
            background-image : url(../img/Antzoki/abril/2.png);
            background-position :  center top;
        }
        .c{
            background-image : url(../img/Antzoki/abril/3.png);
            background-position : center;
        }
        .d {
            background-image : url(../img/Antzoki/abril/4.png);
            background-position : center;
        }
        .e {
            background-image : url(../img/Antzoki/abril/5.png);
            background-position: center ;
        }
        .f {
            background-image : url(../img/Antzoki/abril/6.png);
            background-position: center;
        }
       
    }
}

/* SANTA ANA KULTUR ETXEA */
.grid_berangoetaKultura {
    width          : 100vw;
    padding-bottom : 5%;
    h2{
        @include h2_movil();
    }
   
    .a{background-image : url(../img/menu/bg2.jpg);}
    .b{background-image : url(../img/menu/berangoeta1.jpg);}      
    .c{background-image : url(../img/menu/liburutegia.png);} 
    .d{background-image : url(../img/menu/berangoeta2.jpg);}
    .e{background-image : url(../img/menu/aretoa.jpg);
       border-bottom    : .5rem solid $blanco;
    }
    .a,.b,.c,.d,.e {
        background-position : left top;
        border-bottom       : 1px solid $blanco;
        
        @include grid_movil(); 
        height : calc(100vh / 5);
        
        h3{    
            font-size : .8em;  
            a {
                @include enlace();
            }          
        }
        &:hover{
            opacity : 1;
        }  
    } 
}
/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .grid_berangoetaKultura {
        font-size : var(--fz_body_movil);
    }
}
@media screen and (min-width : 55em) {
    .grid_berangoetaKultura {
        padding-bottom : 0;

        @include grid();
        .a,.b{
            border-bottom : none;
        }
        .a,.b,.c,.d,.e{
            border-right : $border_color_blanco;  
            height       : unset;
            h3{
                font-size : 1vmax;
                a{
                    display : block;
                    width   : 100%;
                }
            }          
        }
        .c,.d, .e{
            border-bottom : $border_color_blanco;
        }
        .a {          
            grid-area :  1 / 1 / 17 /22;            
        }
        .b{           
            grid-area : 1 / 22 / 17 / 33;         
        }
        .c{          
            grid-area : 17 / 1 / 33 / 11;   
            background-position: center;        
        }
        .d{   
            grid-area : 17 / 11 / 33 / 22;           
        }
        .e{   
            grid-area : 17 / 22 / 33 / 33;           
        }       
    }
}

/* SANTA ANA KULTUR ETXEA */
.grid_berangoKirolak{
    width: 100vw;
    height: 100vh;
    padding: 5rem 0 0;
    display: flex;
    align-items: center;
    flex-direction: column;

    h1{
        text-align: center;
        padding: 1rem;
        font-size: 2rem;
    }
    img{
       width: 100%;
       height: auto;
    }
    
    p{
       a{
        color: #000;
       }
    }
   
}

@media screen and (min-width : 55em) {
    .grid_berangoKirolak{
        width: 70%;
        height: 100%;
        padding: 0;
        .imagen{
             width: 80%;
             height: auto;
        }
    }
}

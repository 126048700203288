
.gridLasterMaiatza {
    .lista{
       
        .a {
            background-image : url(../img/Antzoki/mayo/1.jpg);
            background-position: center top;
        }
        .b{
            background-image : url(../img/Antzoki/mayo/2.jpg);
            background-position :  center top;
        }
        .c{
            background-image : url(../img/Antzoki/mayo/3.jpg);
            background-position : center;
        }
        .k{
            background-image : url(../img/Antzoki/mayo/3-5.jpg);
            background-position : center;
        }
        .d {
            background-image : url(../img/Antzoki/mayo/4.jpg);
            background-position : center;
        }
        .e {
            background-image : url(../img/Antzoki/mayo/5.jpg);
            background-position: center ;
        }
        .f {
            background-image : url(../img/Antzoki/mayo/6.jpg);
            background-position: center;
        }
        .g {
            background-image : url(../img/Antzoki/mayo/7.jpg);
            background-position: center top;
        }
        .h {
            background-image : url(../img/Antzoki/mayo/8.jpg);
            background-position : center;
        }
        .i{
            background-image : url(../img/Antzoki/mayo/9.jpg);
            background-position: 40% center;
        }
        .j{
            background-image : url(../img/Antzoki/mayo/10.jpg);
            background-position: 40% center;
        }
    }
}

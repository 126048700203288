
.box {
    // width: 80%;
    margin: -1em auto;
    background-clip: padding-box;
    text-align: center;
    a{
        color: #026ECE;     
    }
  }
  
  .button {
    display: block;
    // font-size: 1em;
    padding: 4px  10px;
    color: #000;
    border: 1px solid $azul_berango;
    // border-radius: 20px/50px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  @media (min-width: 768px) {  
    
  }
  .button:hover {
    background: $azul_berango;
    color : $blanco;
  }
  
  .overlay {
    position: fixed;
    max-width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
  }
  .overlay:target {
    visibility: visible;
    opacity: 1;
  }
  
  .popup {
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    // padding: 50px;
    background: rgba(#fff, .85);
    position: absolute;
    transition: all 3s ease-in-out;
    color: #000;
 
    top: 0;
    border-radius: 0; 
    width: 100%;
    margin: 0 auto;
    padding: 7rem 0;
    .close{
      position: absolute;
      top: 2px;
      right: 7px;
    }
  }
  
  .popup h2 {
    margin-top: 0;
    color: #000;
    font-size: 4rem;
  }
  .popup .close {
    position: absolute;
    top: 0;
    right: 0;
    transition: all 200ms;
    font-size: 5rem;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    padding: 0 1.5rem;
    background: $azul_berango;
    z-index: 1000;
    
  }
  .popup .close:hover {
    background: #333;
  }
  .popup {
    .content{  
      overflow-y: auto;
      overscroll-behavior-y: contain;
      margin-top: -20vh; 
      max-width: 80rem;
        
      a p{
        text-align: center;
        text-transform: uppercase;
        color : $blanco;
        font-weight: bold;
        font-size: larger;
        background-color: $azul_berango;
        padding: 1em;
        transition:ease-in-out 0.5s;
        
        &:hover{
          background-color: #333;
        }
        
      }
      
      h2{
          font-size: 1rem;
      }
      h3{
        margin-bottom: 3rem;
        font-size: 1.4rem;
      }
      p{
          font-size: 1.7rem;
          text-align: left;
      }
    }
   
  }

  @media screen and (max-width: 700px){
    .box{
        margin: -.5em auto;
    }
    .popup{
      width: 100%; 
      top: 0;
      border-radius: 0;
     
      // width: 100%;
      // margin: 8vh auto;
      // padding: 2px .5rem;
      .close{
        // position: absolute;
        top: 6rem;
        right: 0;
      }
      .content{
        overflow-y: auto;
        overscroll-behavior-y: contain;
        margin-top: 0;
        padding: 2rem 1.2rem 5rem ;
          h3{
            font-size: 1.9rem;
            margin-bottom: 1rem;
          }
          p{
            font-size: 1.2rem;
            line-height: 2;
          }
      }
    
      h2{
        position: relative;
        padding: 3rem;
        width: 100%;
        font-size: 2rem;
        margin-top: -1rem;
        text-align: center;
        background-color:#333;
        color: #fff;
        z-index: 10;
      }
     
    }
  }
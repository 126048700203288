    ::-webkit-scrollbar {
        display : none;
    }
    #slide-show {
        height     : 100vh;
        min-height : 100vh;
        overflow   : hidden;
        width      : 100vw;
        min-width  : 100vw !important;
    }
    .slidex {
        opacity    : 0;
        transition : opacity 1.5s ease-in-out;
        z-index    : -1;
    }
    .activx {
        opacity : 1;
    }
    .slideshow-nav {
        position   : absolute;
        bottom     : 45%;
        width      : 100vw;
        min-width  : 100vw !important;
        background : transparent;
    }
    .previousx {
        float : left;
    }
    .nextx {
        float : right;
    }

// -----------------------------------------

    .nav-link {
        transition : all 0.4s;
        padding    : 15px;
        color      : rgba($rojo, .7);
        font-size  : 40px;
    }

    .nav-link:hover {
        color : rgba($rojo, 1);
    }

    @media screen and (min-width : 55em) {
        .nav-link {
            font-size : 60px;
        }

    }

.grid_jaiak{
    // padding-top: 4rem;
    h1{
        font-size: 3rem;
        text-align: center;
        color: rgb(2,110,206);
        position:relative;
        padding: 4rem 0 2rem;
    }
    .a, .b, .c, .d{ 
        min-width: 30%;
        margin-bottom: 1rem;
        h2{
            background-color:rgb(2,110,206);
            padding: 1rem;
            text-align: center;
            font-size: 2rem;
            color: #fff;
        }
        h2,p{
            padding: 0 1rem;
        }
        span{
            width: 5rem;
            text-align: center;
            font-weight: bold;
        }   
        img:last-of-type{
            margin-bottom: 5rem;
        }

        .sexista{
            margin: 0 auto; 
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
    
            a{
                color: $negro;
                padding: 2rem;
                font-weight: bold;
                margin-bottom: 2rem; 
                img{
                    max-width: 45%;
                    margin: 0 auto;
                }
            }
        }
    }
    

    img { 
       margin-bottom: 2rem;
       padding: 1rem 2rem 2rem;
    }
}
@media screen and (min-width : 55em) {
    .grid_jaiak{
        background-color: #efefef;
        width: 100vw;
        height: 100%;
        margin-top: 10rem;
        display               : grid; 
        grid-template-columns : repeat(auto-fit, minmax(15rem, 1fr));
        gap                   : 1rem;
        padding               : 0 1rem;
        .a, .b, .c, .d{ 
            background-color: #fff;
        }

        h1{
            position: absolute;
            width: 100%;
            margin-top:-12rem;
            text-align: center;
        }
        img { 
            width: 100%;
         }
       
    }
}

.logo-berangogaur{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 0 2rem 0;
   
    h1{
        font-size: 5rem;
        padding-right: 2rem;
    }
   
    img{
        width: 45%;
    };
}

.grid_berangoGaur{
    h1{
        color: $blanco;
        text-align: center;
        padding: 1rem 2rem;
        &:hover{
            color: $negro;
            background-color: rgba($blanco, .5);
        }
    }
    .logoBG{
        width: 200px;
        height: auto;
        margin: 0 auto;
    }
    
    .a,.b,.c,.d,.e,.f,.g,.h,.i,.j,.k,.l{    
        width               : 100%;    
        border-bottom       : 1px solid $border_color;       
        opacity             : .9;
      
        @include grid_movil(); 
        height : calc(100vh / 3.5); 
              
        &:hover{
            opacity: 1;
        } 
        .bglogo{
            width: 20%;
        }       
    }
    .a.img-mes, .b.img-mes, .c.img-mes, .d.img-mes{
        display             : block;
        background-position : center;
        background-size     : cover; 
    }
    .a.img-mes{
        background-image : url(../img/berangoGaur/enero.webp);                        
    }
    .b.img-mes{
        background-image : url(../img/berangoGaur/febrero.webp);            
    }    
    .c.img-mes{
        background-image : url(../img/berangoGaur/marzo.webp);         
    }   
    .d.img-mes{
        background-image : url(../img/berangoGaur/abril.webp);         
    }        
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .grid_liburutegiaMenu{
        font-size : var(--fz_body_movil);
    }
}

@media screen and (min-width : 55em) {
   
    .logo-berangogaur{
       
        img{
            width: 10%;
        };
    }
    .grid_berangoGaur{
        @include grid();
              
        .a,.b,.c,.d,.e,.f,.g,.h,.i,.j,.k,.l{
            border-bottom : unset;
            border-top   : 1px solid $border_color;
            border-right : 1px solid #fff;  
            background-color: rgba($gris, .8);
            height: 100%;   
        }
        .i,.j,.k,.l{
            border-bottom : 1px solid $border_color;
        }
       
        .a {
            grid-column : 1 / 9;
            grid-row    : 1 / 11;
        }
        .b {
            grid-column : 9 / 17;
            grid-row    : 1 / 11;
        }
        .c {
            grid-column : 17 / 25;
            grid-row    : 1 / 11;
        }
        .d {
            grid-column : 25 /33;
            grid-row    : 1 / 11;
        }
        .e {
            grid-column : 1 / 9;
            grid-row    : 11 / 21;
        }
        .f {
            grid-column : 9 / 17;
            grid-row    : 11 / 21;
        }
        .g {
            grid-column : 17 / 25;
            grid-row    : 11 / 21;
        }
        .h {
            grid-column : 25 /33;
            grid-row    : 11 / 21;
        }
        .i {
            grid-column : 1 / 9;
            grid-row    : 21 / 31;
        }
        .j {
            grid-column : 9 / 17;
            grid-row    : 21 / 31;
        }
        .k {
            grid-column : 17 / 25;
            grid-row    : 21 / 31;
        }
        .l {
            grid-column : 25 /33;
            grid-row    : 21 / 31;
        }       
    }
}
.gridLaster{
    width      : 100vw;
    margin-top : 7.8rem;
}
.gridLasterUrtarrila,.gridLasterOtsaila,.gridLaster_Martxoa,.gridLasterApirila,.gridLasterMaiatza, .gridLasterEkaina,
.gridLasterUztaila,.gridLasterAbustua,.gridLasterIraila, .gridLasterUrria,.gridLasterAzaroa,.gridLasterAbendua 
{
    .lista {
        display        : flex; 
        flex-direction : column;
        background     : $negro;
        border-bottom  : $border_color;

        &__item {
            display: flex;
            min-height : 20rem;
            
            .date{
                width            : 30vw;
                display          : flex;
                align-items      : center;
                justify-content  : center;
                flex-direction   : column;
                background-color : $negro;
                color            : $blanco;
                padding          : 1rem; 
                border-right     : $border_color ;
              
                h2{                    
                    width            : 50px;
                    height           : 50px;
                    display          : flex;
                    justify-content  : center;
                    align-items      : center;
                    border-radius    : 60%;
                    background-color : $blanco;
                    color            : $negro;
                }
            }
            .a,.b,.c,.d,.e,.f,.g,.h,.i,.j,.k,.l,.ll,.m,.n,.o,.p,.q,.r,.s,.t,.u,.v,.w,.x,.xx,.y,.z{
                width               : 100%;
                background-size     : cover;
                background-position : center;
            }            
        }
               
        .a_item {
            background : rgba(#F0F2F5, 1);
            color      : $negro; 
            padding    : 0 0 2rem 0;
    
            h4{
                color         : $negro;
                padding       : 1rem ;
                margin-bottom : 1rem;
                font-weight   : 700;
            }

            p {
                padding       : 0 1rem;
                margin-bottom : 2rem;
            }
        }      
    }
    .item:last-child{
        border-bottom : none;
    }
    // todo: LATERAL
    .lateral{
        .logotipo{
            display : none;
        }
        h1{
            position    :absolute;
            top         : 4rem;
            text-align  : right;
            width       : 100%;
            padding     : .3vh .2vw 0;
            line-height : 1;
            color       : $azul_berango;
            z-index     : 1000;
            font-size: 2rem;
        }
        h2{
            display : none;
        }
        .imagenes{
            display : none;
        }
        .meses p{
            display               : grid;
            grid-template-columns : repeat(3, 1fr);
            padding               : 1.5rem 0;
            background            : #000;
            font-size             : 1.5vh;

            a{
                transition   : all .25s;
                color        : $azul_berango;
                padding-left : 2vw;
               .meses__item{
                    margin : 0 1em;
                }
                &:hover{
                    color : $rojo;
                }
            }

            .rojo__item{
                display : block;
                color   : $blanco;

                &:hover{
                    color : $azul_berango;
                }
            }
        }
    }
}

//todo: PC
@media screen and (min-width : 55em) {
    .gridLaster{
        height : 100vh;
        margin-top : 0;
    }
    .gridLasterUrtarrila,.gridLasterOtsaila,.gridLaster_Martxoa,.gridLasterApirila,.gridLasterMaiatza, .gridLasterEkaina,
    .gridLasterUztaila,.gridLasterAbustua,.gridLasterIraila, .gridLasterUrria,.gridLasterAzaroa,.gridLasterAbendua{
        height      : 100vh;
        overflow-y  : scroll;
                
        .lista{
            min-height : 30rem;
            flex-direction: row;
            
            &__item {
                .date{
                    width: 6vw;
                    h2{
                        width  : 50px;
                        height : 50px;
                    }
                }
               
                .a,.b,.c,.d,.e,.f,.g,.h,.i,.j,.k,.l,.ll,.m,.n,.o,.p,.q,.r,.s,.t,.u,.v,.w,.x,.xx,.y,.z{
                    width        : 18vw;
                }
            }
            .a_item {
                width : 50vw;

                p {
                    padding       : 0 1rem;
                    margin-bottom : 2rem;
                    text-wrap   : balance;
                }
            }                       
        }
        .lateral{
            position         : fixed;
            top              : 0;
            right            : 0;
            width            : 27vw;
            height           : 100vh;
            display          : flex;
            flex-direction   : column;
            background-color : $negro;

            .logotipo {
                display : inline;
                padding : .5em 1vw 0;
                img{
                    width  : 90px;
                    height : 90px;
                }
            }
            h1{
                position    :none;
                margin-top  : .5rem;
                text-align  : center;
                font-weight : 600;
                font-size   : 3rem;
                a{
                    position   : absolute;
                    right      : 1em;
                    color      : $azul_berango;
                    transition : all ease-in-out .6s;
                    &:hover{
                        color : #fff;
                    }
                }
            }
            .imagenes{
                display : block;

                img {
                    position  : absolute;
                    width: 92%;
                    margin: 4% 4%;
                    animation : slideshow 18s linear 0s infinite;
                    border: 1px solid $azul_berango;
            
                }

                img:nth-child(2) {
                    z-index         : 2;
                    animation-delay : 6s;
                }

                img:nth-child(3) {
                    z-index         : 1;
                    animation-delay : 12s;
                }

                @keyframes slideshow {
                   25% { opacity: 1;}
                   33.33% { opacity: 0;}
                   91.66% { opacity: 0;}
                   100% { opacity: 1;}
                }
            }
  
            .meses p{
                width    : 100%;
                position : absolute;
                bottom   : 0;
                padding  : 1vh 0;
                z-index  : 10;
                a{
                    transition: all .55s;
                   .meses__item{
                        margin : 0 1em;
                    }
                    &:hover{
                        color : $blanco;
                    }
                }               
            }
        }
    }
}


.grid_santana {
    width            : 100%;
    background-image : url(../img/menu/santaAna.jpg);
    line-height      : 6;
    background-size  : cover;
    background-position: center 45%;
    h2{
        text-align    : center;
        border-top    : $border_color_blanco;
        border-bottom : $border_color_blanco;
        a{
            @include enlace();
        }
    }
}


.gridLasterAzaroa {
    .lista{   
        .a {
            background-image : url(../img/Antzoki/noviembre/1.jpg);
            background-position: center top;
        }
        .b{
            background-image : url(../img/Antzoki/noviembre/2.jpg);
            background-position :  center top;
        }
        .c{
            background-image : url(../img/Antzoki/noviembre/3.jpg);
            background-position : center;
        }
        .k{
            background-image : url(../img/Antzoki/noviembre/3-5.jpg);
            background-position : center;
        }
        .d {
            background-image : url(../img/Antzoki/noviembre/4.jpg);
            background-position : center;
        }
        .e {
            background-image : url(../img/Antzoki/noviembre/5.jpg);
            background-position: center ;
        }
        .f {
            background-image : url(../img/Antzoki/noviembre/6.jpg);
            background-position: center;
        }
        .g {
            background-image : url(../img/Antzoki/noviembre/7.jpg);
            background-position: center top;
        }
        .h {
            background-image : url(../img/Antzoki/noviembre/8.jpg);
            background-position : center top;
        }
        .i{
            background-image : url(../img/Antzoki/noviembre/9.jpg);
            background-position: 40% center;
        }
        .j{
            background-image : url(../img/Antzoki/noviembre/10.jpg);
            background-position: 40% center;
        }
        .a,.b,.c,.d,.e,.f,.g,.h,.i,.j{
            background-size:cover;
            background-repeat: no-repeat;
        }
    }
}

/* SANTA ANA KULTUR ETXEA */
.grid_actividades_santaAna{
    width: 100vw;
    padding-bottom: 5%;
    h2{
        @include h2_movil();
    }
    
    .a{background-image : url(../img/menu/1.jpg);}
    .b{background-image : url(../img/menu/2.jpg);}
    .c{background-image : url(../img/menu/3.jpg);}
    .d{background-image : url(../img/menu/4.jpg);}
    .e{background-image : url(../img/menu/5.jpg);}
    .f{background-image : url(../img/menu/6.jpg);}
    .g{background-image : url(../img/menu/7.jpg);}
    .h{background-image : url(../img/menu/8.jpg);}

    .a,.b,.c,.d,.e,.f,.g,.h{
        background-position: left top;
        border-bottom   : 1px solid $blanco;

        opacity: .9;
        @include grid_movil(); 
        height: calc(100vh / 5); 
        
        h3{       
            a {
                @include enlace();
            }          
        }
        &:hover{
            opacity: 1;
        } 
    }
    .e,.h{
        background-position: center 15%;
    }
    .b{
        background-position: center 35%;

    }
    
}
/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .grid_actividades_santaAna{
        font-size : var(--fz_body_movil);
    }
}
@media screen and (min-width : 55em) {
    .grid_actividades_santaAna{
        
        @include grid();
        .h{border-bottom : unset}
        .d,.h{
            background-position: 25% center;
        }
        .a,.b,.c,.d,.e,.f,.g,.h{
            border-bottom   : unset;
            border-top   : $border_color_blanco;
            border-right : $border_color_blanco;  
            height: calc(100vh / 2);       
        }
        .e,.f,.g,.h{
            border-bottom : $border_color_blanco;
        }
        .a {
            grid-column : 1 / 9;
            grid-row    : 1 / 17;
        }
        .b {
            grid-column : 9 /17;
            grid-row    : 1 / 17;
        }
        .c{
            grid-column: 17 / 25;
            grid-row: 1 / 17;
        }
        .d{
            grid-column: 25 / 33;
            grid-row: 1 / 17;
        }
        .e{
            grid-column: 1 / 9;
            grid-row: 17 / 33;
        }
        .f{
            grid-column: 9 / 17;
            grid-row: 17 / 33;
        }
        .g{
            grid-column: 17 / 25;
            grid-row: 17 / 33;
        }
        .h{
            grid-column: 25 / 33;
            grid-row: 17 / 33;
        }
    }
}

@mixin box-sizing($box-model){
    box-sizing : $box-model;
    margin             : 0;
    padding            : 0;
}

@mixin imagen(){
    max-width : 100%;
    height    : auto;   
    display   : block;
}

// ENLACES
@mixin enlace(){
    font-weight      : 400;    
    padding          : 5rem 1.5rem;
    text-align       : center;
    position         : relative;
    background-color : rgba($negro, .3);
    display          : inline;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

// BUTTON{
@mixin button {
    padding : 1vh .5vw;
    margin-bottom: 3vh;
    background-color: #7ED957;
    color: $blanco;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2vh;
    outline: none;
    border: none;
    border-radius: .75vh;
    transition: all .6s ease-in-out;
    cursor: pointer;    
    &:hover{
        background-color: $azul_berango;
    }
}

//  GRID
@mixin grid(){
    display               : grid;
    display               : -ms-grid;
    grid-template-columns : repeat(32, 3.124vw);
        -ms-grid-columns  : repeat(32, 3.124vw);
    grid-template-rows    : repeat(32, 3.125vh);
        -ms-grid-rows     : repeat(32, 3.125vh);
    border-left           : $border_color ; 
}
// GRID AREA MOVIL
@mixin grid_movil(){
    display         : flex;
    justify-content : center;
    align-items     : center;
    width           : 100%;
    // line-height     : 8;       
    background-size : cover;      
    border-top      : $border_color_blanco;  
}

// H2
@mixin h2{
    width      : 100%;            
    transition : all .5s;
    &:hover {
        background-color : rgba($negro, .3);
    }
}
//  H2 MÓVIL
@mixin h2_movil(){
    background-color : $blanco;
    color            : $negro;
    text-align       : center;
    font-weight      : 800;
}


@mixin a(){
    text-align: center;
    font-weight: 400;
    background-color: rgba($negro, .3);
    padding: $padding;
   
}
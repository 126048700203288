
.gridLasterUztaila {
    .lista{
        .x{
            background-image : url(../img/imgEventos/2021/julio/0.jpg);
            background-position : center;
        }
        .a {
            background-image : url(../img/imgEventos/2021/julio/1.jpg);
            background-position: center top;
        }
        .b{
            background-image : url(../img/imgEventos/2021/julio/2.jpg);
            background-position :  center top;
        }
        .c{
            background-image : url(../img/imgEventos/2021/julio/3.jpg);
            background-position : center;
        }
        .k{
            background-image : url(../img/imgEventos/2021/julio/3-5.jpg);
            background-position : center;
        }
        .d {
            background-image : url(../img/imgEventos/2021/julio/4.jpg);
            background-position : center;
        }
        .e {
            background-image : url(../img/imgEventos/2021/julio/5.jpg);
            background-position: center ;
        }
        .f {
            background-image : url(../img/imgEventos/2021/julio/6.jpg);
            background-position: center;
        }
        .g {
            background-image : url(../img/imgEventos/2021/julio/7.jpg);
            background-position: center top;
        }
        .h {
            background-image : url(../img/imgEventos/2021/julio/8.jpg);
            background-position : center;
        }
        .i{
            background-image : url(../img/imgEventos/2021/julio/9.jpg);
            background-position: 40% center;
        }
        .j{
            background-image : url(../img/imgEventos/2021/julio/10.jpg);
            background-position: 40% center;
        }
    }
}

.grid_liburutegiaMenu{
    width: 100vw;
    // padding-bottom: 5%;
    h2{
        @include h2_movil();
    } 
    .a{background-image : url(../img/lectura/1.webp);}
    .b{background-image : url(../img/lectura/2.webp);}
    .c{background-image : url(../img/lectura/3.webp);}
    .d{background-image : url(../img/lectura/4.webp);}
    .e{background-image : url(../img/lectura/5.webp);}
    .f{background-image : url(../img/lectura/6.webp);}
    .g{background-image : url(../img/lectura/7.webp);}
    .h{background-image : url(../img/lectura/8.webp);}
    .i{background-image : url(../img/lectura/9.webp);}
    .j{background-image : url(../img/lectura/10.webp);}
    .k{background-image : url(../img/lectura/11.webp);}
    .l{background-image : url(../img/lectura/12.webp);}
    
    .a,.b,.c,.d,.e,.f,.g,.h,.i,.j,.k,.l{
        background-position: top;
        border-bottom   : 1px solid $blanco;
       
        opacity: .9;
        @include grid_movil(); 
        height: calc(100vh / 3.5); 
        
        h3{       
            a {
                @include enlace();
            }          
        }
        &:hover{
            opacity: 1;
        } 
    }

    .b{
        background-position: center 35%;
    }
    
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .grid_liburutegiaMenu{
        font-size : var(--fz_body_movil);
    }
}
@media screen and (min-width : 55em) {
   
    .grid_liburutegiaMenu{
        
        @include grid();
        
        .a,.b,.c,.d,.e,.f,.g,.h,.i,.j,.k,.l{
            border-bottom   : unset;
            border-top   : $border_color_blanco;
            border-right : $border_color_blanco;  
            height: 100%;      
        }
       
        .a {
            grid-column : 1 / 9;
            grid-row    : 1 / 11;
        }
        .b {
            grid-column : 9 / 17;
            grid-row    : 1 / 11;
        }
        .c {
            grid-column : 17 / 25;
            grid-row    : 1 / 11;
        }
        .d {
            grid-column : 25 /33;
            grid-row    : 1 / 11;
        }
        .e {
            grid-column : 1 / 9;
            grid-row    : 11 / 21;
        }
        .f {
            grid-column : 9 / 17;
            grid-row    : 11 / 21;
        }
        .g {
            grid-column : 17 / 25;
            grid-row    : 11 / 21;
        }
        .h {
            grid-column : 25 /33;
            grid-row    : 11 / 21;
        }
        .i {
            grid-column : 1 / 9;
            grid-row    : 21 / 31;
        }
        .j {
            grid-column : 9 / 17;
            grid-row    : 21 / 31;
        }
        .k {
            grid-column : 17 / 25;
            grid-row    : 21 / 31;
        }
        .l {
            grid-column : 25 /33;
            grid-row    : 21 / 31;
        }
       
    }
}
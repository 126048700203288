.grid_berangoeta {
    width            : 100%;
    background-image : url(../img/menu/berangoeta.jpg);
    line-height      : 6;
    background-size  : cover;
    background-position: center;
    h2{
        text-align    : center;
        border-top    : $border_color_blanco;
        border-bottom : $border_color_blanco;
        font-family   : var(--body_tipo);
        a{
           @include enlace();
        }
    }
}


.gridLasterAbendua {
    .lista{
        .x{
            background-image : url(../img/Antzoki/diciembre/0.jpg);
            background-position : center;
        }
        .a {
            background-image : url(../img/Antzoki/diciembre/1.jpg);
            background-position: center top;
        }
        .b{
            background-image : url(../img/Antzoki/diciembre/2.jpg);
            background-position :  center top;
        }
        .c{
            background-image : url(../img/Antzoki/diciembre/3.jpg);
            background-position : center;
        }
        .d {
            background-image : url(../img/Antzoki/diciembre/4.jpg);
            background-position : center;
        }
        .e {
            background-image : url(../img/Antzoki/diciembre/5.jpg);
            background-position: center ;
        }
        .f {
            background-image : url(../img/Antzoki/diciembre/6.jpg);
            background-position: center;
        }
        .g {
            background-image : url(../img/Antzoki/diciembre/7.jpg);
            background-position: center top;
        }
        .h {
            background-image : url(../img/Antzoki/diciembre/8.jpg);
            background-position : center;
        }
        .i{
            background-image : url(../img/Antzoki/diciembre/9.jpg);
            background-position: 40% center;
        }
        .j{
            background-image : url(../img/Antzoki/diciembre/10.jpg);
            background-position: 40% center;
        }
        .k{
            background-image : url(../img/Antzoki/diciembre/11.jpg);
            background-position : center;
        }
        .l{
            background-image : url(../img/Antzoki/diciembre/12.jpg);
            background-position : center;
        }
        .ll{
            background-image : url(../img/Antzoki/diciembre/13.jpg);
            background-position : center;
        }
        .m{
            background-image : url(../img/Antzoki/diciembre/14.jpg);
            background-position : center;
        }
        .n{
            background-image : url(../img/Antzoki/diciembre/15.jpg);
            background-position : center;
        }
    }
}

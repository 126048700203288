.grid_inauteriak {
    max-width : 100%;
    height    : 100vh;

    .cabecera-inauteriak{
        margin-top: 1rem;
        padding-bottom : 2rem;
        text-align     : center;
        
        h1{
            font-size   : 2rem;        
            font-weight : 700;
            transform: scale(1, 1.6);
            letter-spacing:.1rem;
            padding-bottom : 2rem;
        }
        h2{
            text-align : center;
            letter-spacing:.3rem;
            color      : $azul_berango;
            & span{
                font-weight : bold;
                color       : $blanco;
            }
            background-color : #000;
            padding          : .5rem;
        }
    }  
   
   .contenedor-inauteriak{
        padding : 0 1rem;

        & img{
            width : 100%;
            border: 1px solid $negro;
        }

        .textos-carnaval{
            padding-top: 1rem;
            padding-left : 1rem;
            & .negrita{
                font-weight : bold;
            }
            a{
                color: brown;
            }
        }
   }

   .carnaval{
        margin-bottom : 2rem;
        border-bottom  : 1px solid #e1e1e1;
        padding-bottom : 1.5rem;
        &:nth-child(2){
            border-bottom  : none;
        }

        // h2{
        //     padding-bottom : 1.5rem;
        // }
   }
}

@media screen and (min-width : 55em) {
    .grid_inauteriak {
        .cabecera-inauteriak{ 
            padding-top: 2rem;                      
            h1{                
                transform: scale(1, 2.5); 
                letter-spacing:.5rem;              
            }           
        }  
                
        .max-width-h2{
            max-width : 80rem;
            margin: 0 auto;
        }
       
        .contenedor-inauteriak{
            max-width : 80rem;            
            margin    : 0 auto;            
            // border    : 1px solid #000;
        }
        .eventos-carnaval{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            // background-color: $gris;
            & img{
                text-align: right;
            }
        }
        .textos-carnaval{
            display: flex;
            flex-direction: column;
            justify-content: center;
            &:nth-child(1){
                margin-right: 0;
            }
    
        }

    }    
}
//////////////  VARIABLES //////////////

// FUENTES
$fuente_principal : system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
// $fuente-cabeceras : "Montserrat", serif;

// COLOR
$blanco       : #fafafa;
$negro        : #1f1e1e;
$gris         : #3b3b3b;
$rojo         : #FD0137;
$morado       : #662583;
$azul_berango : #0071bc;

// BORDES
$border_color        : 1px solid $blanco;
$border_color_blanco : 1px solid $blanco;

// PADDING
$padding : 1rem;




//todo: NAVEGACIÓN MÓVIL
main{
    margin-top: 3.5rem;
}
.content__title {
    margin         : 0;
    position       : relative;
    font-weight    : 400;
    width          : 100%;
    font-size: 2rem;
    text-transform : uppercase;
    padding        : .3rem 1rem .1rem;
    letter-spacing : .1rem;
}
.grid_agenda_movil,
.grid_santana_movil,
.grid_berangoeta_movil,
.grid_inauteriak_movil,
.grid_liburutegia_movil,
.grid_jaiak_movil,
.grid_museoa_movil,
.grid_berangoGaur_movil{
   
    width               : 100%;
    background-size     : cover;
    background-position : center;  
    line-height         : 20;
   
    h2{
       text-align    : center;
       font-family   : var(--body_tipo);
       font-size: 1.9rem;
    }     
    a{
        @include enlace();
    }
}

//todo: IMÁGENES MENÚ MOVIL

.grid_agenda_movil {
    background-image : url(../img/menu/backgroundAntzokia.webp);
    background-position : center top;
}
.grid_berangoeta_movil {
    background-image : url(../img/menu/berangoeta.webp);
}
.grid_inauteriak_movil {    
    background-image : url(../img/menu/kluba_eus.png);
}
.grid_liburutegia_movil {    
    background-image : url(../img/menu/liburutegia.webp);
}
.grid_museoa_movil {    
    background-image : url(../img/menu/museo.webp);
}
.grid_berangoGaur_movil {    
    background-image : url(../img/menu/berangogaur.webp);    
}

.menu-trigger {
    display : none;
}
.grim {
    display : none;
}
.content--switch {
    background-size  : cover;
    margin           : 0;
    padding          : 0;
    display: block;
}
.menu_item {
    z-index: 5;
    position: relative;
    text-shadow: 1px 0 #1f1e1e;
}
#content-1 {
    background-image      : url(../img/menu/backgroundAntzokia.webp);
    background-position   : center top;
    background-size       : cover;
    background-attachment : fixed;

   /* iPads (landscape) Alternativa a flexbox */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: landscape) {
        #content-1 {
            background-repeat   : no-repeat;
            background-position : fixed;
        }
    }
}

// todo: NAVEGACIÓN PC

@media screen and (min-width : 55em) {
    main{
        margin-top: 0;
    }
    .menu_item {
        width            : calc(100% - 2px);
        display          : table;
        background-color : rgba(#1f1e1e, .3);
        transition       : all 1s;
        &:hover{
            background-color : rgba(#1f1e1e, .55);
        }
    }
    .menu-trigger {
        display          : inline-block;
        border           : 0;
        grid-area        : menu;
        justify-self     : start;
        align-self       : end;
        cursor           : pointer;
        pointer-events   : auto;
        color            : #fafafa;
        position         : relative;
        padding          : 0;
        margin           : 0;
        white-space      : nowrap;
        background-color : $azul_berango ;
        border           : 1px solid #fafafa;
        padding          : .5rem 1rem;
        text-transform   : uppercase;
        margin-bottom    : -1vh;
        transition       : background-color .6s;
    }
    .menu-trigger:hover {
         background-color : rgba($azul_berango, .8);
    }
    .menu-trigger--close {
        display         : flex;
        align-items     : center;
        justify-content : center;
        padding         : .5em 0;
    }
    .menu-trigger--close a {
        margin-top : -.45em;
    }
    .menu-trigger--close a:hover {
        opacity    : 0.8;
        transition : opacity 0.3s;
    }
    .grim {
        @include grid();
        position              : fixed;
        z-index               : 1000;
        bottom                : 0;
        left                  : 0;
        width                 : 100vw;
        height                : 100vh;       
        pointer-events        : none;
    }
    .grim--open {
        pointer-events : auto;
    }
    .grim__item {
        position : relative;
        overflow : hidden;
        /* Flexbox da problemas en safari y firefox. Anulandolo funciona en todos los navegadores.*/
        display         : flex;
        justify-content : center;
        align-items     : center;
    }

    /* iPads (landscape) Alternativa a flexbox */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {

        .menu-trigger {
            margin-bottom : 1em;
        }
        .grim__item {
            display : table;
            width   : 100%;
            height  : 100%;
        }
    }

    .grim__item:nth-child(1) {
        // grid-area : 31 / 1 / 33 / 3;
        -ms-grid-column      : 1;
        -ms-grid-column-span : 2;
            grid-column      : 1 / 3;
        -ms-grid-row         : 31;
        -ms-grid-row-span    : 2;
            grid-row         : 31 / 33;
    }
    .grim__item:nth-child(3) {
        // grid-area : 29 / 1 / 31 / 3;
        -ms-grid-column      : 1;
        -ms-grid-column-span : 2;
            grid-column      : 1 / 3;
        -ms-grid-row         : 29;
        -ms-grid-row-span    : 2;
            grid-row         : 29 / 31;
    }
    .grim__item:nth-child(4) {
        // grid-area : 29 / 3 / 33 / 5;
        -ms-grid-column      : 3;
        -ms-grid-column-span : 2;
            grid-column      : 3 / 5;
        -ms-grid-row         : 29;
        -ms-grid-row-span    : 4;
            grid-row         : 29 / 33;
    }
    .grim__item:nth-child(5) {
        // grid-area : 25 / 1 / 29 / 5;
        -ms-grid-column      : 1;
        -ms-grid-column-span : 4;
            grid-column      : 1 / 5;
        -ms-grid-row         : 25;
        -ms-grid-row-span    : 4;
            grid-row         : 25 / 29;
    }
    .grim__item:nth-child(6) {
        // grid-area : 25 / 5 / 33 / 9;
        -ms-grid-column      : 5;
        -ms-grid-column-span : 4;
            grid-column      : 5 / 9;
        -ms-grid-row         : 25;
        -ms-grid-row-span    : 8;
            grid-row         : 25 / 33;
    }
    .grim__item:nth-child(7) {
        // grid-area : 17 / 1 / 25 / 9;
        -ms-grid-column      : 1;
        -ms-grid-column-span : 8;
            grid-column      : 1 / 9;
        -ms-grid-row         : 17;
        -ms-grid-row-span    : 8;
            grid-row         : 17 / 25;
    }
    .grim__item:nth-child(8) {
        // grid-area : 17 / 9 / 33 / 17;
        -ms-grid-column      : 9;
        -ms-grid-column-span : 8;
            grid-column      : 9 / 17;
        -ms-grid-row         : 17;
        -ms-grid-row-span    : 16;
            grid-row         : 17 / 33;
    }
    .grim__item:nth-child(9) {
        // grid-area : 1 / 1 / 17 / 17;
        -ms-grid-column      : 1;
        -ms-grid-column-span : 16;
            grid-column      : 1 / 17;
        -ms-grid-row         : 1;
        -ms-grid-row-span    : 16;
            grid-row         : 1 / 17;
    }
    .grim__item:nth-child(10) {
        // grid-area : 1 / 17 / 33 / 33;
        -ms-grid-column      : 17;
        -ms-grid-column-span : 16;
            grid-column      : 17 / 33;
        -ms-grid-row         : 1;
        -ms-grid-row-span    : 32;
            grid-row         : 1 / 33;
    }
    .grim__item-bg {
        position   : absolute;
        width      : 100%;
        height     : 100%;
        top        : 0;
        left       : 0;
        opacity    : 0;
        background : currentColor;
    }
    /* Capas de color */
    .grim__item-bg--1 {
        display          : flex;
        align-items      : center;
        justify-content  : center;
    }
    .grim__item-bg--1 img {
        max-width : 90%;
        height: auto;
        padding: .7vh 0 0 1vw;
        text-align: center;
    }
    .grim__item-bg--1 a {
        padding : .2em .2rem;
    }
    .grim__item-bg--1 a:hover {
        opacity : .6;
    }
 
    .grim__item-bg--3 {
        background-size: center;
    }
 
    .grim__item-img {
        position            : absolute;
        width               : 100%;
        height              : 100%;
        opacity             : 0;
        background-position : left top;
        background-size     : cover;
    }
    /* Posición texto de background */  
    // .grim__item-img--1{
    //     background-color: $negro;  
    // }
    // .grim__item-img--3{  
    //     background-color: $azul_berango ;   
    //     background-image : url(../img/idioma.png);
    // }
    // todo: Imágenes PC
    .grim__item-img--4{
        background-image : url(../img/menu/museo.webp);        
    }
    .grim__item-img--5{
        background-image : url(../img/menu/berangogaur.webp);        
    }
    .grim__item-img--6{
        background-image : url(../img/menu/kluba_eus.png);        
    } 
    .grim__item-img--7{
        background-image : url(../img/menu/liburutegia.webp);        
    }
    .grim__item-img--8{
        background-image : url(../img/menu/berangoeta.webp);        
    }
    .grim__item-img--9{
        background-image : url(../img/menu/gala.png);  
        background-position:  center top;     
    }
    .grim__item-img--10{
        background-image : url(../img/menu/agenda.webp);        
    }

     /* Color de Bordes en background */
    .grim__item-img--1,.grim__item-img--3,.grim__item-img--4,.grim__item-img--5,.grim__item-img--6,.grim__item-img--7,.grim__item-img--8,.grim__item-img--9,.grim__item-img--10{
        border-right : $border_color_blanco;
        border-top   : $border_color_blanco;
    }
    .grim__item-img--1,.grim__item-img--4,.grim__item-img--6,.grim__item-img--8,.grim__item-img--10{
        border-bottom : $border_color_blanco;
    }

    /* iPads (landscape) ----------- */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        .grim__item-content {
            display        : table-cell;
            vertical-align : middle;
        }
    }
    .grim__item-content {
        width: calc(100% - 2px);
    }
    /* Textos de los grid */
    .grim__item-inner {
        padding          : .3em 2.5rem;
        display          : flex;
        flex-direction   : column;
        justify-content  : center;
        align-items      : center;
        position         : relative;
        opacity          : 0;
        background-color : rgba(#121212, .4);
        z-index          : 1000;
        h2{
            letter-spacing:.4rem; 
            font-size: 1.5rem;
            font-family: $fuente_principal;
            text-align: center;
            text-align: center;
        }
        .subtitle {
            padding          : .5em 1.5rem;
            border-radius    : .2em;
        }
        .idiomas{
            a:focus{
                color : $rojo;
            }
            .idiomas--item {
                &:hover {
                    color: $rojo;
                }
            }
        }
    }
    // todo: TEXTOS TITULOS
    .grim__item-title {
        font-weight : normal;
        margin      : 0;
        font-size   : 4rem;
        text-align  : center;
    }
    .grim__item:nth-child(-n+8) .grim__item-title {
        font-size : 1.7vmax;       
    }
    .grim__item:nth-child(7) .grim__item-title {
        font-size : 1.4vmax;
    }
    .grim__item:nth-child(6) .grim__item-title {
        font-size : 1.2vmax;
    }
    .grim__item:nth-child(5) .grim__item-title {
        font-size :.80vmax;
        // letter-spacing:.2rem;
    }
    .grim__item:nth-child(4) .grim__item-title {
        font-size :.90vmax;
    }
    .grim__item:nth-child(1) .grim__item-title {
        font-size : .90vmax;
    }
    .eventos{
        display          : flex;
        justify-content  : center;
        align-items      : center; 
        background-color : rgba($negro, .3);
    }   
}

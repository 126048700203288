.gridLasterOtsaila {
    .lista{
        &__item{
       
            .a {
                background-image : url(../img/Antzoki/febrero/1.jpg);            
            }
            .b{
                background-image : url(../img/Antzoki/febrero/2.jpg);   
                background-position: center top;        
            }
            .c{
                background-image : url(../img/Antzoki/febrero/3.jpg);  
                background-position: center 65%;          
            }
            .d {
                background-image : url(../img/Antzoki/febrero/4.jpg);            
            }
            .e {
                background-image : url(../img/Antzoki/febrero/5.jpg);  
                background-position: center top;           
            }
            .f {
                background-image : url(../img/Antzoki/febrero/6.jpg);           
            }
            .g {
                background-image : url(../img/Antzoki/febrero/7.jpg);   
                background-position: center top;              
            }
            .h {
                background-image : url(../img/Antzoki/febrero/8.jpg);           
            }
        }
    }
}

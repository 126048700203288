
.gridLasterUrria {
    .lista{
        .d {
            background-image : url(../img/Antzoki/octubre/4.jpg);
            background-position: center top;
        }
       
        .e {
            background-image : url(../img/Antzoki/octubre/0.png);
            background-position: center top;
        }
        .a {
            background-image : url(../img/Antzoki/octubre/1.png);
            background-position: center top;
        }
        .b{
            background-image : url(../img/Antzoki/octubre/2.jpg);
            background-position :  center;
        }
        .c{
            background-image : url(../img/Antzoki/octubre/3.jpg);
            background-position : center;
        }
        .k{
            background-image : url(../img/Antzoki/octubre/3-5.jpg);
            background-position : center;
        }
        
        .j{
            background-image : url(../img/Antzoki/octubre/10.jpg);
            background-position: 40% center;
        }
    }
}
